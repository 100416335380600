import React from 'react';

import Layout from '@/components/Layout';
import SEO from '@/components/Seo';

export default function Home() {
  return (
    <Layout>
      <SEO
        title="Holzarbeiten GmBh"
        description="Givan Holzarbeiten GmBh"
        keywords={[
          `Givan`,
          `Holzbau`,
          `Holzarbeiten`,
          `Bern`,
          `Schweiz`,
          `Schreinerei`,
          `bauen`,
          `Zimmerei`,
        ]}
      />
      &nbsp;
    </Layout>
  );
}
